import React from "react"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <footer>
      <h1 className="footer-logo">
        <Link to="/">Like a Daughter</Link>
      </h1>
      <nav>
        <div className="menu-footer-container">
          <ul id="menu-footer" className="menu">
            <li>
              <Link to="/sitemap/">Sitemap</Link>
            </li>
            <li>
              <Link to="/contact/">Contact</Link>
            </li>
          </ul>
        </div>
      </nav>
      <p className="copyright">
        &copy; {new Date().getFullYear()} Like a Daughter
      </p>
    </footer>
  )
}

export default Footer
