import { Link } from "gatsby"
import React from "react"
import { slide as Menu } from "react-burger-menu"
import { Location } from "@reach/router"

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = { scrolled: false }
  }

  componentDidMount() {
    document.addEventListener("scroll", () => {
      if (typeof window !== `undefined`) {
        const scrollHeight = window.pageYOffset
        this.setState({ scrolled: scrollHeight > 0 })
      }
    })
  }
  render() {
    const { scrolled } = this.state
    return (
      <Location>
        {({ location }) => {
          return (
            <>
              <Menu right>
                <Link to="/">Home</Link>
                <Link to="/about/">About</Link>
                <Link to="/team/">Team</Link>
                <Link to="/testimonials/">Testimonials</Link>
                <Link to="/gallery/">Gallery</Link>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://thisisreno.com/2020/10/caregivers-adjust-how-they-support-loved-ones-during-pandemic/#h-masks-social-distancing-become-a-barrier"
                >
                  Media
                </a>
                <Link to="/contact/">Contact</Link>
              </Menu>
              <header>
                <h1 id="logo" className="logo">
                  <Link to="/">Like a Daughter</Link>
                </h1>
                <nav id="top-nav">
                  <div className="menu-main-container">
                    <ul id="menu-main" className="menu">
                      <li>
                        <Link to="/about/">About</Link>
                      </li>
                      <li>
                        <Link to="/team/">Team</Link>
                      </li>
                      <li>
                        <Link to="/testimonials/">Testimonials</Link>
                      </li>
                      <li>
                        <Link to="/gallery/">Gallery</Link>
                      </li>
                      <li>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href="https://thisisreno.com/2020/10/caregivers-adjust-how-they-support-loved-ones-during-pandemic/#h-masks-social-distancing-become-a-barrier"
                        >
                          Media
                        </a>
                      </li>
                      <li>
                        <Link to="/contact/">Contact</Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </header>
            </>
          )
        }}
      </Location>
    )
  }
}

export default Header
